@import 'src/styles/typography.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;

  .text {
    margin-left: 36px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.containerButtons {
  display: flex;
  gap: 50px;
}

.containerImg {
  background: #d4e0f0;
  border-radius: 50%;
  height: 206px;
  width: 250px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 66px;
  margin: 66px 0;
}

.marginTen {
  margin-bottom: 27px;
}

.marginDouble {
  margin-bottom: 60px;
}
